






import { getCurrentInstance, defineComponent } from '@vue/composition-api'

import { ProgressBarProps } from './ProgressBar.contracts'
import { progressBarProps, useProgressBar } from './ProgressBar.hooks'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
export const ProgressBar = defineComponent({
  name: 'ProgressBar',
  props: progressBarProps,

  setup (props: ProgressBarProps) {
    return useProgressBar(
      props,
      getCurrentInstance()
    )
  }
})

export default ProgressBar
