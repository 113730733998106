import { ComponentInternalInstance, toRef } from '@vue/composition-api'

import { canHaveThemeProp, useThemeClass } from '../../composables'
import { ComponentObjectPropsOptions } from '../../vue-api'

import { ProgressBarProps, UseProgressBarProvides } from './ProgressBar.contracts'
import { progressBarThemeClassRegistry } from './ProgressBar.config'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
export const progressBarProps: ComponentObjectPropsOptions<ProgressBarProps> = {
  /**
   * @see ProgressBarProps.theme
   */
  ...canHaveThemeProp,

  /**
   * @see BadgeProps.maxValue
   */
  maxValue: {
    type: Number,
    required: false,
    default: 100
  },

  /**
   * @see BadgeProps.value
   */
  value: {
    type: Number,
    required: true
  }
}

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
export const useProgressBar = (
  props: ProgressBarProps,
  initialInstance: ComponentInternalInstance | null
): UseProgressBarProvides => {
  if (!initialInstance) {
    throw new Error('No component instance is found!')
  }

  const { type } = initialInstance

  /**
   * @see UseProgressBarProvides.themeClass
   */
  return useThemeClass(toRef(props, 'theme'), progressBarThemeClassRegistry, `${type._componentTag}`)
}
